/*

プロジェクトにおける基本的なスタイル
（ページ全体の背景や、基本的なタイポグラフィ）
※ クラスは使わない

*/

* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

html,body {
	-webkit-font-smoothing: antialiased;
	width: 100%;
}

body{
	font-family: YakuHanJP_Noto, "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
	-webkit-text-size-adjust: 100%;
	word-wrap: break-word;
	@include rem(font-size, 1.4rem);
	line-height: 1.8;
	color: $color_text;
	letter-spacing: .03em;

	@include mq(liquid) {
		font-size: .86vw;
	}

	@include mq(sp) {
		@include rem(font-size, 1.4rem);
	}

	&.admin-bar {
		.l-site_header {
			top: 32px;
		}
	}
}

img {
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

a{
	color: $color_link;
	text-decoration: none;

	&:hover{
		text-decoration: underline;
	}
}

#loading {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: #fff;
	z-index: 50;
	visibility: visible;
	transition: opacity 1s, visibility 1s;

	&.is_loaded {
		opacity: 0;
		visibility: hidden;
	}

	&__logo {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
}