/*

ボタン

*/

.btn {
	&--primary {
		a,input {
			background-color: #202027;
			color: #fff;
			@include rem(font-size, 1.8rem);
			-webkit-appearance: none;
			border: none;
			border-radius: 0;
			padding: 30px;
			letter-spacing: 2px;
			cursor: pointer;
			width: 100%;
			transition: opacity .3s;

			@include mq(sp, true) {
				min-width: 350px;
			}

			@include mq(liquid) {
				font-size: 1.18vw;
			}

			@include mq(sp) {
				padding: 20px;
				@include rem(font-size, 1.4rem);
			}

			&:hover {
				opacity: 0.6;
				text-decoration: none;
			}
		}
	}

	&--back,
	&--submit {
		a,input {
			background-color: #202027;
			color: #fff;
			@include rem(font-size, 1.8rem);
			-webkit-appearance: none;
			border: none;
			border-radius: 0;
			padding: 30px;
			letter-spacing: 2px;
			cursor: pointer;
			width: 100%;
			transition: opacity .3s;

			@include mq(sp, true) {
				min-width: 350px;
			}

			@include mq(liquid) {
				font-size: 1.18vw;
			}

			@include mq(sp) {
				padding: 20px;
				@include rem(font-size, 1.4rem);
			}

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&--back {
		a,input {
			background-color: #aaa;
		}
	}

	&--hover {
		position: relative;
		padding-bottom: 15px;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-color: $color_text;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: transform .2s;
			transform: scaleX(0);
			transform-origin: center bottom;
		}
		&:hover {
			text-decoration: none;

			&::before {
				transform: scaleX(1);
			}
		}
	}
}
