.column_container{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include mq(liquid) {

	}

	@include mq(sp) {
		flex-direction: column;
	}
}

.primary_column{
	width:$width_main_column;

	@include mq(liquid) {

	}

	@include mq(sp) {
		width: 100%;
	}
}

.secondary_column{
	width:$width_sub_column;

	@include mq(sp) {
		width: 100%;
		margin-top: 60px;
	}

	.box {
		margin-bottom: 65px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	h3 {
		@include rem(font-size, 2.5rem);
		margin-bottom: 30px;
		font-family: $font_opensans;
		font-weight: 400;
		letter-spacing: 2px;
	}

	.category {
		li {
			margin-bottom: 20px;
			max-width: 180px;

			@include mq(sp) {
				max-width: 100%;
			}

			a {
				border-bottom: 1px solid $color_line;
				position: relative;
				display: block;
				padding-bottom: 5px;
				padding-left: 5px;
				transition: padding .2s;

				&::after {
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
					content: ">";
					transition: right .2s;
				}

				&:hover {
					padding-left: 15px;
					text-decoration: none;

					&::after {
						right: 0px;
					}
				}
			}
		}
	}
}
