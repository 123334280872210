/*

標準のマーカーを使ったリスト

*/

%liMargin{
	> li{
		margin-bottom: 7px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.list_disc{
	margin-left:1.2em;
	list-style:disc;
	@extend %liMargin;
}

.list_square{
	margin-left:1.2em;
	list-style:square;
	@extend %liMargin;
}

.list_decimal{
	margin-left:1.7em;
	list-style:decimal;
	@extend %liMargin;
}

.list_decimal_zero{
	margin-left:2.3em;
	list-style:decimal-leading-zero;
	@extend %liMargin;
}

/*

beforeでマーカーを作ったリスト (IE8以上)

*/

/* ---- ・ ---- */

.list_dot{
	> li{
		text-indent:-1em;
		margin-left:1em;
		&:before{
			content:"・";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- ● ---- */

.list_circle{
	> li{
		text-indent:-1em;
		margin-left:1em;
		&:before{
			content:"● ";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- 1. ---- */
.list_decimal_01{
	> li{
		counter-increment:decimal_01;
		margin-left:1.2em;
		text-indent:-1.2em;
		&:before{
			content:counter(decimal_01) ". ";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- (1) ---- */
.list_decimal_02{
	> li{
		counter-increment:decimal_02;
		margin-left:1.5em;
		text-indent:-1.5em;
		&:before{
			content:"(" counter(decimal_02) ") ";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- [1] ---- */
.list_decimal_03{
	> li{
		counter-increment:decimal_03;
		margin-left:1.5em;
		text-indent:-1.5em;
		&:before{
			content:"[" counter(decimal_03) "] ";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- 丸数字 ---- */
.list_decimal_04{
	> li{
		counter-increment:decimal_04;
		margin-left:1.5em;
		text-indent:-1.5em;
		&:before{
			content:counter(decimal_04);
			border:1px solid #000;
			padding:0 3px;
			font-size:10px;
			margin-right:5px;
			text-align:center;
			line-height:1;
			border-radius: 50%;
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}

/* ---- upper alpha ---- */
.list_upper_alpha_01{
	> li{
		counter-increment:upper_alpha_01;
		margin-left:1.5em;
		text-indent:-1.5em;
		&:before{
			content:counter(upper_alpha_01, upper-alpha) ") ";
		}
		& > *{
			text-indent:0;
		}
	}
	@extend %liMargin;
}
