/*for modern browser*/
.clear_fix:after{
    content:".";
    display:block;
    height:0;
    clear:both;
	font-size:0;
    line-height:0;
    visibility:hidden;
}

/*for IE 5.5-7*/
.clear_fix{
	zoom:1;
}

.clear_both{
	clear:both;
	height:1px;
	font-size:1px;
	text-align:center;
	line-height:0;
}

.clear{
	clear:both;
}
