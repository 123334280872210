@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    color: #fff;
    background-color: #191B1F;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 10;
    transition: opacity .3s;

    @media screen and (max-width: 768px) {
        height: 50px;
        width: 50px;
        line-height: 50px;
    }

    &:hover, &:focus {
        outline: none;
        opacity: 0.6;
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-size: 22px;
        line-height: 60px;
        font-weight: 600;

        @media screen and (max-width: 768px) {
            line-height: 50px;
        }
    }
}

.slick-prev {
    left: 15px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: 15px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    list-style: none;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        border: #d0d0d0 1px solid;
        color: #aeaeae;
        font-size: 1.3rem;
        border-radius: 50%;
        position: relative;
        width: 34px;
        height: 34px;
        line-height: 34px;
        background-color: transparent;
        opacity: 1;
        margin: 0 10px;
        outline: none;

        svg {
            display: none;
        }
        &.slick-current,
        &.slick-active {
            svg {
                display: block;
            }
            circle {
                fill: transparent;
                stroke: #939393;
                stroke-width: 1;
                animation: circle 8.8s forwards;
                animation-timing-function: linear;
            }
        }

        svg {
			transform: rotate(-90deg);
			position: absolute;
			left: -2px;
			top: -2px;
            z-index: 2;
		}

		@keyframes circle {
			0% { stroke-dasharray: 0 113; }
			100%,to { stroke-dasharray: 113 113; }
		}

        button {
            border: 0;
            background: transparent;
            display: block;
            outline: none;
            cursor: pointer;
            text-align: center;
            line-height: 34px;
            width: 100%;
            position: relative;
            z-index: 1;
            color: #939393;
            &:hover, &:focus {
                outline: none;
            }
        }
    }
}
