/*

fluid image

*/

.fluid_img{
	max-width:100%;
	height:auto;
	width:auto\9; /* IE8 */;
}
