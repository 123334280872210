.breadcrumbs{
	background-color:#eee;
	&__inner{
		width:$width_site;
		margin:0 auto;

		@include mq(liquid) {
			width:100%;
			box-sizing: border-box;
			padding-left:30px;
			padding-right:30px;
		}

		@include mq(sp) {
			padding-left:20px;
			padding-right:20px;
		}
	}
	li{
		font-size:fs(11);
		line-height:1;
		padding:10px 0;
		display: inline-block;
		&:before{
			content:">";
			padding-right:5px;
		}
		&:first-child:before{
			content:"";
			padding-right:0;
		}
		a{
			color:$color_text;
		}
	}
}
