.wp-pagenavi {
	margin: 3% auto 9%;
	display: flex;
	justify-content: center;

	a,span {
		@include rem(font-size, 2.0rem);
		display: inline-block;
		padding: 15px 25px;
		opacity: 0.4;
		line-height: 1;
		transition: opacity .3s;

		@include mq(liquid) {
			font-size: 1.28vw;
		}

		@include mq(sp) {
			@include rem(font-size, 1.4rem);
			padding: 10px 15px;
		}

		&.current {
			opacity: 1;
		}

		&:hover {
			text-decoration: none;
			opacity: 1;
		}
	}

	.previouspostslink,
	.nextpostslink {
		position: relative;
		display: block;
		top: -3px;
		&::before,
		&::after {
			content: "";
			display: block;
			width: 2px;
			height: 15px;
			background-color: $color_line;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);


			@include mq(sp) {
				height: 10px;
			}
		}

		&::before {
			margin-top: -9px;

			@include mq(sp) {
				margin-top: -6px;
			}
		}

		&::after {
			margin-bottom: -9px;

			@include mq(sp) {
				margin-bottom: -6px;
			}
		}
	}

	.previouspostslink {
		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}

	.nextpostslink {
		&::before {
			transform: rotate(-45deg);
		}

		&::after {
			transform: rotate(45deg);
		}
	}
}