/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, main {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}

body {
	line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,main {
	display:block;
}

ol, ul {
	list-style:none;
}

blockquote, q {
	quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}

a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}

/* change colours to suit your needs */
ins {
	background-color:#ff9;
	color:#000;
	text-decoration:none;
}

/* change colours to suit your needs */
mark {
	background-color:#ff9;
	color:#000;
	font-style:italic;
	font-weight:bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom:1px dotted;
	cursor:help;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

/* change border colour to suit your needs */
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #cccccc;
	margin:1em 0;
	padding:0;
}

input, select {
	vertical-align:middle;
}

input, textarea{
	margin:0;
	padding:0;
}

small{
	font-size:smaller;
}

sup{
	font-size:0.7em;
	vertical-align:top;
}

sub{
	font-size:0.7em;
	vertical-align:baseline;
}



/* YUI 3.9.1 (build 5852) Copyright 2013 Yahoo! Inc. http://yuilibrary.com/license/ */
/**
 * Percents could work for IE, but for backCompat purposes, we are using keywords.
 * x-small is for IE6/7 quirks mode.
 */
body {
	font:#{nth($basic_font_size, 2)}/1.231 arial,sans-serif;
	*font-size:small; /* for IE */
	*font:x-small; /* for IE in quirks mode */
}

/**
 * Nudge down to get to 13px equivalent for these form elements
 */
select,
input,
button,
textarea {
	font:99% arial,sans-serif;
}

/**
 * To help tables remember to inherit
 */
table {
	font-size:inherit;
	font:100%;
}

/**
 * Bump up IE to get to 13px equivalent for these fixed-width elements
 */
pre,
code,
kbd,
samp,
tt {
	font-family:monospace;
	*font-size:108%;
	line-height:100%;
}
/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssfonts { display: none; }
