.animate {
	opacity: 0;
	transform: translateY(10px);
	transition: opacity .5s, transform .6s;

	&.is_animated {
		filter: brightness(1);
		transform: translateY(0);
		opacity: 1;
	}
}