.top_content,
.lower_content{
	p,li,dt,dd{
		line-height:1.75;
	}

	* + p, * + ul, * + ol{
		margin-top:15px;
		margin-bottom:15px;
	}

	pre{
		margin:10px 0 20px;
		background-color:#333;
		border:1px solid #e5e5e5;
		color:#fff;
		padding:10px;
		font-family:"Courier New", Courier, monospace;
		border-radius:4px;
		line-height:1.3;
		overflow:auto;
		text-align:left;

		.comment{
			color:#999;
		}

		.red{
			color:skyblue;
		}
	}
}
