.toggle,
.accordion{
	&:last-of-type{
		border-bottom: 1px solid $color_line;
	}
	&__title{
		border-top: 1px solid $color_line;
		display: block;
		padding: 10px 0;
		cursor: pointer;
		&:hover{
			background-color: #f1f1f1;
		}
		&:before{
			content:"+";
			display: inline-block;
			width: 18px;
			text-align: center;
		}
	}
	&__content{
		padding: 15px;
	}
	&.selected {
		.title:before{
			content:"-";
		}
	}
}
