/*

error404 page

*/

body.error404{

	.p-error404 {
		text-align: center;

		.p-heading_lv1 {
			margin-bottom: 30px;
		}

		.p-heading_lv2 {
			margin-bottom: 30px;
		}

		.btn {
			margin-top: 20px;
		}
	}

}
