.p-banner {
	width: 70%;
	max-width: 900px;
	margin: 2.5% auto 0;
	display: flex;
	align-items: center;

	@include mq(tablet) {
		width: 80%;
	}

	@include mq(sp) {
		margin-top: 14%;
		width: 100%;
	}

	&--works {
		a {
			background-image: url(../img/common/image_works_01.jpg);

			@include mq(sp) {
				background-image: url(../img/common/image_works_01_sp.jpg);
			}
		}
	}

	&--flow {
		a {
			background-image: url(../img/common/image_flow_01.jpg);

			@include mq(sp) {
				background-image: url(../img/common/image_flow_01_sp.jpg);
			}
		}
	}

	a {
		display: flex;
		width: 100%;
		padding: 5% 6%;
		color: #fff;
		flex-direction: column;
		justify-content: center;
		background-size: cover;
		background-position: center;
		position: relative;
		transition: opacity .3s;

		@include mq(sp, true) {
			min-height: 200px;
		}

		@include mq(sp) {
			padding: 8% 6%;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}

		dl {
			position: relative;
			z-index: 2;
		}

		dt {
			@include rem(font-size, 2.3rem);

			@include mq(liquid) {
				font-size: 1.5vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.4rem);
			}
		}

		dd {
			@include mq(sp) {
				@include rem(font-size, 1.0rem);
				width: 50%;
			}
		}

		.more {
			opacity: 0.6;
			@include rem(font-size, 1.3rem);
			font-family: $roboto;
			margin-top: 20px;

			@include mq(sp) {
				@include rem(font-size, 1.1rem);
			}
		}

		.icon {
			position: absolute;
			left: 34%;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);

			@include mq(sp) {
				width: 24%;
				left: 40%;
				top: 10%;
				transform: translateY(0);
			}
		}
	}
}