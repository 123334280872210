/*

ブレイクポイントごとのキーワードとマージンの組み合わせを作る

```sass
@include create_margins(
	(
		default:(
			l:50px,
			m:40px,
			s:30px,
			xs:20px
		),
		tablet:(
			l:40px,
			m:30px,
			s:25px
		),
		sp:(
			l:30px,
			m:25px,
			s:20px,
			xs:15px
		)
	)
)
```

それぞれのキーワードに対する上下左右のマージン用クラスを
ブレークポイントの数だけ出力します

```output css
.rmb_l { margin-bottom: 50px !important; }
.rmt_l { margin-top: 50px !important; }
.rmr_l { margin-right: 50px !important; }
.rml_l { margin-left: 50px !important; }
～略～

@media screen and (max-width: 959px) {
  .rmb_l { margin-bottom: 40px !important; }
  .rmt_l { margin-top: 40px !important; }
  .rmr_l { margin-right: 40px !important; }
  .rml_l { margin-left: 40px !important; }
	～略～
}

@media screen and (max-width: 679px) {
  .rmb_l { margin-bottom: 30px !important; }
  .rmt_l { margin-top: 30px !important; }
  .rmr_l { margin-right: 30px !important; }
  .rml_l { margin-left: 30px !important; }
	～略～
}
```

*/

@mixin create_margins($responsive_margins){
	@each $bp, $margins in $responsive_margins {
		@if($bp == default){
			@each $size, $num in $margins{
				.rmb_#{$size} {margin-bottom:$num !important;}
				.rmt_#{$size} {margin-top:$num !important;}
				.rmr_#{$size} {margin-right:$num !important;}
				.rml_#{$size} {margin-left:$num !important;}
			}
		}@else{
			@include mq($bp) {
				@each $size, $num in $margins{
					.rmb_#{$size} {margin-bottom:$num !important;}
					.rmt_#{$size} {margin-top:$num !important;}
					.rmr_#{$size} {margin-right:$num !important;}
					.rml_#{$size} {margin-left:$num !important;}
				}
			}
		}
	}
}
