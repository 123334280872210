.tab{
	max-width: 1200px;
	margin: 0 auto;
	&__btns{
		overflow: hidden;
		display: flex;
		> li{
			width: 25%;
			padding: 20px;
			border-right: 2px solid #fff;
			box-sizing: border-box;
			text-align: center;
			background-color: #E5E5E5;
			position: relative;
			transition: background-color .2s;
			cursor: pointer;

			@include mq(sp) {
				padding: 10px 5px;
				border-right-width: 1px;
			}

			&::before {
				content: "";
				display: block;
				width: 80px;
				height: 100px;
				background-color: #FBDDAD;
				position: absolute;
				left: 50%;
				bottom: 10%;
				z-index: 1;
				opacity: 0;
				transition: opacity .2s, transform .2s;
				transform: scale(.75);

				@include mq(liquid) {
					width: 60px;
					height: 80px;
				}

				@include mq(sp) {
					width: 30px;
					height: 40px;
				}
			}

			a {
				display: block;
				position: relative;
				z-index: 2;
				height: 100%;

				img {
					vertical-align: bottom;
				}
			}

			&:hover{
				background-color: #fff;
			}
			&.selected{
				background-color: #fff;
				border-bottom: 1px solid #fff;
				a {
					cursor: default;
				}
				&::before {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
	&__contents{
		> div{
			padding: 75px 0 30px;

			@include mq(sp) {
				padding: 35px 0 0;
			}
		}

		dl {
			margin-bottom: 40px;

			@include mq(sp) {
				margin-bottom: 30px;
			}
		}

		dt {
			@include rem(font-size, 2.3rem);
			margin-bottom: 10px;
			font-weight: bold;
			line-height: 1;

			@include mq(sp) {
				@include rem(font-size, 1.8rem);
			}

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 24px;
				height: 24px;
				border-radius: 3px;
				background-color: $color_main;
				margin-right: 10px;

				@include mq(sp) {
					width: 16px;
					height: 16px;
					border-radius: 2px;
				}
			}
		}

		dd {
			@include rem(font-size, 1.8rem);

			@include mq(sp) {
				@include rem(font-size, 1.4rem);
			}
		}
	}
}
