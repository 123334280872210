/*

メディアクエリ

ブレークポイント定義
```sass
$breakpoints:(
	tablet: 959px,
	sp: 679px
);
```

● max-widthで指定する
```sass
@include mq(sp) {
	height:40px;
}
```
```output css
@media screen and (max-width: 679px) {
	height: 40px;
}
```

● 範囲指定する場合は、引数に2つ入れる
```sass
@include mq(sp tablet) {
	height:40px;
}
```
```output css
@media screen and (min-width: 680px) and (max-width: 959px) {
	height: 40px;
}
```

● min-widthで指定する場合は第2引数をtrueにする
```sass
@include mq(tablet, true) {
	height:40px;
}
```
```output css
@media screen and (min-width: 960px) {
	height: 40px;
}
```

*/

@mixin mq($point, $min:false) {
	@if( type-of($point) == list ){
		$bp1:'';
		$bp2:'';
		@if(map-get($breakpoints, nth($point,1))){
			$bp1: map-get($breakpoints, nth($point,1))+1;
		}@else{
			$bp1: nth($point,1);
		}
		@if(map-get($breakpoints, nth($point,2))){
			$bp2: map-get($breakpoints, nth($point,2));
		}@else{
			$bp2: nth($point,2);
		}
		@media screen and (min-width: $bp1) and (max-width: $bp2) { @content; }
	}@else if($min){
		@if(map-get($breakpoints, $point)){
			@media screen and (min-width: map-get($breakpoints, $point)+1) { @content; }
		}@else{
			@media screen and (min-width: $point) { @content; }
		}
	}@else{
		@if(map-get($breakpoints, $point)){
			@media screen and (max-width: map-get($breakpoints, $point)) { @content; }
		}@else{
			@media screen and (max-width: $point) { @content; }
		}
	}
}
