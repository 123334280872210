/*

flow page

*/

body.flow{

	.p-flow {

		&__section {
			margin-top: 5%;
			text-align: center;

			@include mq(sp) {
				margin-top: 10%;
			}

			+ .p-flow__section {
				margin-top: 9%;

				@include mq(sp) {
					margin-top: 14%;
				}
			}

			.images {
				margin-top: 4%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;

				@include mq(sp) {
					margin-top: 7%;
				}
			}

			&.sec_01 {
				.images {
					@include mq(sp, true) {
						padding: 0 5%;
					}

					.image {
						position: relative;

						&--01 {
							width: 28%;
						}

						&--02 {
							width: 20%;
							margin-top: -3%;
							margin-right: -2%;
						}

						&--03 {
							width: 23%;
							margin-top: 4%;
							margin-left: -4%;
						}

						&--04 {
							width: 24%;
						}
					}
				}
			}

			&.sec_02 {
				.images {
					@include mq(sp, true) {
						padding: 0 3%;
					}

					.image {
						&--01 {
							width: 23%;
						}

						&--02 {
							width: 16%;
							margin-top: 7%;
							margin-right: -4%;
							z-index: 2;
						}

						&--03 {
							width: 15%;
							margin-top: -2%;
							margin-left: -4%;
							z-index: 1;
						}

						&--04 {
							width: 19%;
							margin-top: 9%;
						}

						&--05 {
							width: 19%;
						}
					}
				}
			}

			&.sec_03 {
				.images {
					@include mq(sp, true) {
						padding: 0 4%;
					}

					margin-top: 8%;

					.image {
						&--01 {
							width: 21%;
						}

						&--02 {
							width: 31%;
							margin-top: 2%;
							margin-bottom: auto;
							margin-left: 6%;
							margin-right: auto;
						}

						&--03 {
							position: absolute;
							right: 10%;
							top: -28%;
							width: 30%;
						}

						&--04 {
							width: 27%;
							margin-bottom: 0;
							margin-top: auto;
						}
					}
				}
			}

			&.sec_04 {
				margin-bottom: 14%;
				.images {
					@include mq(sp, true) {
						padding: 0 5%;
					}

					.image {
						&--01 {
							width: 28%;
						}

						&--02 {
							width: 25%;
							margin-left: -6%;
							margin-top: auto;
							margin-bottom: -8%;
						}

						&--03 {
							width: 19%;
						}

						&--04 {
							width: 26%;
						}
					}
				}
			}
		}
	}

}
