

.l-home_section{
	padding: 8% 0;

	@include mq(sp) {
		padding: 10% 0;
	}

	&__inner{
		width: $width_site;
		max-width: 1280px;
		margin:0 auto;
		position: relative;
		

		@include mq(liquid) {
			width: 88%;
		}
	}
}

.l-page_content{
	margin-top: 4%;
}

.l-page_main_image {
	background-size: cover;
	background-position: center;

	&__inner{
		width: $width_site;
		max-width: 1420px;
		margin:0 auto;
		position: relative;
		height: 55vh;
		max-height: 640px;

		@include mq(liquid) {
			height: 40vh;
			width: 90%;
		}

		.p-heading_lv1 {
			position: absolute;
			left: 0;
			bottom: 0;
			color: #fff;
			margin-bottom: 110px;

			@include mq(sp) {
				margin-bottom: 40px;
			}

			b {
				&::after {
					background-color: #fff;
				}

				span {
					min-width: 15%;
				}
			}
		}
	}
}

.l-page_section{
	padding: 8% 0;

	@include mq(sp) {
		padding: 20% 0 18%;
		overflow-x: hidden;
	}

	&__inner{
		width: $width_site;
		max-width: 1280px;
		margin:0 auto;
		position: relative;
		transition: width .2s;

		@include mq(liquid) {
			width: 88%;
		}
	}
}

.bg {
	&--gray {
		background-color: #ebebeb;
	}
}