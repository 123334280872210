/*

about page

*/

body.about{

	.p-concept {
		background-image: url(../img/about/bg_concept_01.jpg);
		background-size: cover;
		background-position: center;
		text-align: center;
		position: relative;
		padding: 18% 0 10%;
		@include rem(font-size, 1.8rem);
		line-height: 2.6;

		@include mq(sp) {
			padding: 30% 0 18%;
			@include rem(font-size, 1.1rem);
		}

		.p-heading_lv2 {
			margin-bottom: 5%;
		}

		.leaf_01 {
			position: absolute;
			right: 0;
			top: 10%;
			width: 27%;

			@include mq(sp) {
				top: 9%;
				width: 26%;
			}
		}

		.leaf_02 {
			position: absolute;
			left: 0;
			bottom: 46%;
			width: 16%;
		}

		p {
			color: #3a3c45;
			font-family: $mincho;
			font-weight: 500;

			@include mq(liquid) {
				font-size: 1.21vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.1rem);
			}
		}
	}

	.p-profile {

		@include mq(sp, true) {
			display: flex;
			justify-content: space-between;
			max-width: 960px;
			margin: 0 auto;
		}

		.photo {
			@include mq(sp, true) {
				width: 27%;
			}

			@include mq(sp) {
				width: 50%;
				margin: 0 auto;
			}
		}

		.biography {
			@include mq(sp, true) {
				width: 64%;
			}

			@include mq(sp) {
				margin-top: 6%;
			}

			dl {
				@include rem(font-size, 1.6rem);
				line-height: 2;

				@include mq(liquid) {
					font-size: 1.08vw;
				}

				@include mq(sp) {
					@include rem(font-size, 1.4rem);
					line-height: 1.8;
				}

				+ dl {
					margin-top: 3%;

					@include mq(sp) {
						margin-top: 6%;
					}
				}

				dt {
					font-weight: 600;
				}

				dd {
					@include mq(sp) {
						letter-spacing: -.03em;
					}
				}
			}

			ul {
				li {
					time {
						margin-right: 2em;
						float: left;

						@include mq(sp) {
							margin-right: 1em;
							letter-spacing: 0;
						}
					}

					span {
						display: block;
						overflow: hidden;
						letter-spacing: 0;
					}
				}
			}

		}
	}

}
