.align_l{
	text-align:left !important;
}

.align_r{
	text-align:right !important;
}

.align_c{
	text-align:center !important;
}


.valign_m{
	vertical-align:middle;
}

.valign_t{
	vertical-align:top;
}

.valign_b{
	vertical-align:bottom;
}
