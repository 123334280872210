.p-works {
	&__title {
		position: relative;
		text-align: center;
		// .elem_en {
		// 	@include rem(font-size, 5.5rem);
		// 	font-family: $roboto;
		// 	font-weight: 200;
		// 	letter-spacing: 3px;
		// 	position: relative;
		// 	display: inline-block;
		// 	line-height: 1;

		// 	@include mq(sp) {
		// 		letter-spacing: 2px;
		// 		@include rem(font-size, 3.3rem);
		// 	}

		// 	.momiji_01,
		// 	.momiji_02 {
		// 		position: absolute;
		// 		top: 50%;
		// 		transform: translateY(-50%);

		// 		img {
		// 			vertical-align: middle;
		// 			width: 100%;
		// 			height: auto;
		// 		}
		// 	}

		// 	.momiji_01 {
		// 		right: -50px;
		// 		width: 36px;

		// 		@include mq(sp) {
		// 			right: -25px;
		// 			width: 18px;
		// 		}
		// 	}

		// 	.momiji_02 {
		// 		right: -90px;
		// 		width: 25px;

		// 		@include mq(sp) {
		// 			right: -40px;
		// 			width: 10px;
		// 		}
		// 	}
		// }
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 6%;
	}

	&__item {
		width: 31%;
		margin-right: 3.5%;
		margin-bottom: 3.5%;

		@include mq(sp) {
			margin-right: 6%;
			margin-bottom: 7%;
		}

		&:nth-child(3n) {
			@include mq(sp, true) {
				margin-right: 0;
			}
		}

		@include mq(sp) {
			width: 47%;
		}

		&:nth-child(2n) {
			@include mq(sp) {
				margin-right: 0;
			}
		}

		a {
			display: block;
			position: relative;

			em {
				@include rem(font-size, 1.3rem);
				font-family: $roboto;
				background-color: $color_text;
				color: #fff;
				text-align: center;
				padding: 3px 15px;
				font-style: normal;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;

				@include mq(sp) {
					@include rem(font-size, 1.0rem);
					padding: 2px 10px;
				}
			}

			canvas {
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}

			img {
				width: 100%;
				height: 300px;
				object-fit: cover;
				object-position: center;
				transition: height .2s, opacity .2s;

				@include mq(liquid) {
					height: 18vw;
				}

				@include mq(sp) {
					//height: 115px;
					height: 31vw;
				}
			}

			&:hover img {
				opacity: .6;
				//filter: brightness(1.2);
			}
		}

		&--title {
			margin-top: 4%;
			font-weight: 400;

			@include mq(sp) {
				@include rem(font-size, 1.1rem);
			}
		}
	}

	.more {
		@include rem(font-size, 1.5rem);
		font-family: $roboto;
		font-weight: 300;

		@include mq(liquid) {
			font-size: 1vw;
		}

		@include mq(sp, true) {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		@include mq(sp) {
			text-align: center;
			opacity: 0.6;
			@include rem(font-size, 1.4rem);
		}
	}
}