.global_nav{
	height:50px;
	background-color:$color_main;

	@include mq(sp) {
		height:40px;
	}

	ul{
		width:$width_site;
		margin:0 auto;

		@include mq(liquid) {
			width:100%;
		}

		li{
			float:left;
			width:25%;
			box-sizing: border-box;
			border-right:1px solid rgba(255,255,255,0.1);

			&:first-child{
				border-left:1px solid rgba(255,255,255,0.1);
			}

			@include mq(sp) {
				font-size:fs(11);
			}

			a{
				display:block;
				text-align:center;
				line-height:50px;
				color:#fff;
				transition:all 1s linear;
				&:hover{
					background-color: lighten( $color_main, 10% );
					text-decoration:none;
				}

				@include mq(sp) {
					line-height:40px;
				}
			}

			&.current a{
				background-color: lighten( $color_main, 10% );
			}
		}
	}
}
