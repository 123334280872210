/*

top page

*/

body.home{

	.p-home_hero {
		width: 100%;
		position: relative;

		&__title {
			font-family: $garamond;
			@include rem(font-size, 10.0rem);
			font-weight: 600;
			position: absolute;
			left: 20%;
			top: 50%;
			transform: translate(0,-50%);
			line-height: 1.1;
			z-index: 3;
			mix-blend-mode: color-dodge;
			color: #cdcdcd;
			letter-spacing: 2px;

			@include mq(liquid) {
				font-size: 6.7vw;
			}

			@include mq(sp) {
				@include rem(font-size, 3.0rem);
			}
		}

		$slider_height : 450px;

		&__slider {
			width: 100%;
			height: 100vh;

			@include mq(sp) {
				height: $slider_height;
			}

			&__item {
				background-size: cover;
				background-position: center;
				width: 100%;
				height: 100vh;
				position: relative;

				@include mq(sp) {
					height: $slider_height;
				}

				.title {
					position: absolute;
					left: 5%;
					bottom: 5%;
					z-index: 2;

					@include mq(sp) {
						@include rem(font-size, 1.0rem);
						bottom: 15%;
					}
				}

				.image {
					width: 100%;
					height: 100vh;
					background-position: center;
					background-size: cover;
					transform-origin: center center;

					@include mq(sp) {
						height: $slider_height;
					}
				}
			}
		}

		@keyframes zoomUp {
			0% {
				transform: scale(1);
			}
			100% {
				transform: scale(1.1);
			}
		}

		.swiper-slide-active .image,
		.swiper-slide-duplicate-active .image,
		.swiper-slide-prev .image{
			animation: zoomUp 12s linear 0s 1 normal both;
		}

		svg {
			display: none;
		}

		.swiper-container-horizontal>.swiper-pagination-bullets,
		.swiper-pagination-custom,
		.swiper-pagination-fraction {
			bottom: 50px;

			@include mq(sp) {
				bottom: 20px;
			}
		}

		.swiper-pagination-bullet {
			width: 34px;
			height: 34px;
			line-height: 34px;
			@include rem(font-size, 1.1rem);
			background-color: transparent;
			border-radius: 50%;
			border: #d4d4d4 1px solid;
			color: #b2b2b2;
			margin: 0 10px;
			position: relative;
			opacity: 1;
			font-weight: 400;
			color: #939393;

			@include mq(sp) {
				@include rem(font-size, 1.1rem);
			}

			&.swiper-pagination-bullet-active {
				svg {
					display: block;
				}
				circle {
					fill: transparent;
					stroke: #939393;
					//stroke: #f00;
					stroke-width: 1.5;
					animation: circle 11s forwards;
					animation-timing-function: linear;
				}
			}
		}

		svg {
			transform: rotate(-90deg);
			position: absolute;
			left: -2px;
			top: -2px;
			z-index: 2;
		}

		@keyframes circle {
			0% {
				stroke-dasharray: 0 100;
			}
			100% {
				stroke-dasharray: 100 100;
			}
		}

	}

	.p-news {
		@include mq(sp, true) {
			display: flex;
			align-items: center;
		}

		&__title {
			font-family: $roboto;
			font-weight: 400;
			@include rem(font-size, 2.5rem);
			text-align: center;
			letter-spacing: 1px;

			@include mq(sp, true) {
				width: 22%;
			}

			@include mq(liquid) {
				font-size: 1.5vw;
			}

			@include mq(sp) {
				@include rem(font-size, 2.5rem);
			}

		}

		&__list {
			@include mq(sp, true) {
				border-left: 1px solid #9b9b9b;
				display: flex;
				flex: 1;
				flex-direction: column;
				padding: 1.5% 11%;
			}

			@include mq(sp) {
				padding: 1% 0;
			}
		}

		&__item {
			padding: 18px 0;

			@include mq(sp, true) {
				display: flex;
			}

			a {
				display: block;

				span {
					border-bottom: 1px solid rgba($color_text, 1);
					transition: border-bottom-color .2s;
					padding-bottom: 5px;
					line-height: 2.1;
				}

				&:hover {
					text-decoration: none;

					span {
						border-bottom-color: rgba($color_text, .1);
					}
				}
			}

			.date {
				@include mq(sp, true) {
					margin-right: 40px;
				}
			}

			.title {
				@include mq(sp, true) {
					letter-spacing: 1px;
				}

				@include mq(sp) {
					letter-spacing: 0;
				}
			}
		}
	}

	.p-concept {
		// background-image: url(../img/home/bg_concept_01.jpg);
		// background-size: cover;
		// background-position: center;
		text-align: center;
		position: relative;


		@include mq(sp) {
			margin-top: 15%;
			padding: 18% 0;
		}

		&__title {
			// @include rem(font-size, 1.8rem);
			// font-family: $roboto;
			// font-weight: 500;
			// position: relative;
			// color: #a4a5a5;
			// letter-spacing: 1px;
			// display: inline-block;

			// @include mq(liquid) {
			// 	font-size: 1.21vw;
			// }

			// @include mq(sp) {
			// 	@include rem(font-size, 1.2rem);
			// }

			// &::before,
			// &::after {
			// 	content: "";
			// 	display: block;
			// 	position: absolute;
			// 	top: 50%;
			// 	width: 36px;
			// 	height: 1px;
			// 	background-color: #b2b2b2;
			// }

			// &::before {
			// 	left: -50px;
			// }

			// &::after {
			// 	right: -50px;
			// }
		}

		&__content {
			margin-top: 5%;
			@include rem(font-size, 1.8rem);
			color: #3a3c45;
			line-height: 2.5;

			@include mq(liquid) {
				font-size: 1.21vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.1rem);
				line-height: 2;
			}

			p {
				font-family: $mincho;
			}

			.more {
				margin-top: 5%;
				font-family: $roboto;
				font-weight: 400;
				opacity: 0.6;

				@include mq(sp) {
					margin-top: 12%;
				}
			}
		}

		.leaf_01 {
			position: absolute;
			right: 0;
			top: -4%;
			width: 27%;
		}

		.leaf_02 {
			position: absolute;
			left: 0;
			bottom: 6%;
			width: 16%;
		}

		&__wrap {
			position: relative;
			overflow: hidden;
			padding: 8% 0;

			@include mq(sp) {
				padding: 10% 0;
			}

			.video {
				position: absolute;
				z-index: -1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 177.77777778vh;
				height: 56.25vw;
				min-height: 100%;
				min-width: 100%;
			}
		}

	}

}
