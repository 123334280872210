/*

base font size

*/

$basic_font_size : 13, 81.25%;

/* $basic_font_size : 14, 87.5%; */

/* $basic_font_size : 15, 93.75%; */

/* $basic_font_size : 16, 100%; */

$font_opensans: 'Open Sans', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');
$garamond : 'Cormorant Garamond', serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
$roboto : 'Roboto', sans-serif;

$mincho : YakuHanMP_Noto, "Noto Serif JP", "Hiragino Mincho ProN", "Yu Mincho", YuMincho, serif;