
.loading {
	background-color: #fff;
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 300;
	text-align: center;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		display: block;
		margin-bottom: 50px;
	}
}

.l-site_header{
	position: absolute;
	left: 0;
	top: 3%;
	width: 100%;
	z-index: 100;

	&__inner {
		width: 90%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__id {
		@include mq(liquid) {
			width: 10vw;
		}
		@include mq(sp) {
			width: 14%;
		}
	}

	&__nav {
		@include mq(sp) {
			display: none;
		}
		ul {
			display: flex;

			@include mq(sp) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
			}

			li {
				@include mq(sp, true) {
					margin-left: 60px;
				}

				@include mq(sp) {
					text-align: center;
				}

				&.is_current {
					a {
						&::before {
							transform: scaleX(1);
						}
					}
				}
			}

			a {
				font-family: $roboto;
				font-weight: 400;
				@include rem(font-size, 1.7rem);
				padding: 0 0 5px;
				display: block;
				letter-spacing: 1px;
				position: relative;

				@include mq(liquid) {
					font-size: 1.15vw;
				}

				@include mq(sp, true) {
					//text-shadow: #fff 1px 1px 0;
				}

				@include mq(sp) {
					@include rem(font-size, 2.1rem);
					padding: 20px 0 10px;
					margin-bottom: 20px;
				}

				&::before {
					content: "";
					display: block;
					width: 100%;
					height: 2px;
					background-color: $color_text;
					position: absolute;
					left: 0;
					bottom: 0;
					transition: transform .2s;
					transform: scaleX(0);
					transform-origin: center bottom;
				}
				&:hover {
					text-decoration: none;

					&::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	.sp__ham {
		width: 45px;
		height: 7px;
		position: fixed;
		right: 6%;
		top: 6%;
		transform: translateY(-50%);
		cursor: pointer;
		display: none;
		z-index: 100;
		outline: none;

		@include mq(sp) {
			display: block;
		}

		&__inner {
			position: relative;
			width: 30px;
			height: 15px;
		}

		span {
			display: block;
			width: 100%;
			height: 1px;
			background-color: $color_line;
			position: absolute;
			left: 0;
			transition: transform .3s;
			transform-origin: right center;

			&:first-child {
				top: 0;
			}

			&:nth-child(2) {
				bottom: 0;
			}

			&:nth-child(3) {
				width: 85%;
				left: 9px;
				top: -10px;
				transform: rotate(45deg) scale(0,1);
				transform-origin: left center;
			}
			&:nth-child(4) {
				width: 85%;
				left: 9px;
				bottom: -10px;
				transform: rotate(-45deg) scale(0,1);
				transform-origin: left center;
			}
		}
	}
}

html.is_menu_active {

	body {
		overflow: hidden;
	}

	.l-site_header {
		&__nav {
			display: block;
			position: fixed;
			background-color: rgba(#fff, .95);
			width: 100%;
			height: 100vh;
			left: 0;
			top: 0;
			animation: fadein .3s alternate;
		}
	}

	.sp__ham {
		z-index: 100;

		span {
			&:first-child {
				transform: scale(0,1);
			}

			&:nth-child(2) {
				transform: scale(0,1);
			}

			&:nth-child(3) {
				transform: rotate(45deg) scale(1,1);
			}

			&:nth-child(4) {
				transform: rotate(-45deg) scale(1,1);
			}
		}
	}

	@keyframes fadein {
		0% { opacity: 0; }
		100% { opacity: 1; }
	}
}