/*

グリッドレイアウト用mixin (IE8以上)

引数で分割数を指定して呼び出す
```sass
@include grid_system(12);
```

```html
<div class="grid--12 gutter--2">  ← このdivへの幅指定はNG
	<div class="grid__col--4"></div> ┐
	<div class="grid__col--4"></div> ├ 子要素は合計が分割数になるようにクラス名を付ける
	<div class="grid__col--4"></div> ┘
</div>
```

グリッドの間隔は「gutter--N」で指定する
通常はパーセントですが、「gutter--Npx」にするとピクセルになります。

ブレークポイントで変える場合は、「tablet_」「sp_」など、$breakpointsで定義したキーの接頭辞を付ける
```html
<div class="grid--12 gutter--20px tablet-gutter--15px sp_gutter--10px">
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
</div>
```

*/

@mixin gutter($resp){
	// %
	@for $i from 0 through 20 {
		&.gutter--#{$i}{
			margin-left: #{-$i}#{"%"};
			margin-top: #{-$i}#{"%"};
			> *{
				box-sizing: border-box;
				padding-left: #{$i}#{"%"};
				padding-top: #{$i}#{"%"};
			}
		}
	}

	// px
	@for $i from 0 through 60 {
		&.gutter--#{$i}px{
			margin-left: #{-$i}#{"px"};
			margin-top: #{-$i}#{"px"};
			> *{
				box-sizing: border-box;
				padding-left: #{$i}#{"px"};
				padding-top: #{$i}#{"px"};
			}
		}
	}

	@if $resp{

		@each $key, $value in $breakpoints {
			@include mq($key) {
				// %
				@for $i from 0 through 20 {
					&.#{$key}_gutter--#{$i}{
						margin-left: #{-$i}#{"%"};
						margin-top: #{-$i}#{"%"};
						> *{
							box-sizing: border-box;
							padding-left: #{$i}#{"%"};
							padding-top: #{$i}#{"%"};
						}
					}
				}
				// px
				@for $i from 0 through 60 {
					&.#{$key}_gutter--#{$i}px{
						margin-left: #{-$i}#{"px"};
						margin-top: #{-$i}#{"px"};
						> *{
							box-sizing: border-box;
							padding-left: #{$i}#{"px"};
							padding-top: #{$i}#{"px"};
						}
					}
				}
			}
		}
	}
}

@mixin grid_system( $num:12, $class:"grid--", $children_class:"grid__col--", $resp:true ){
	.#{$class}#{$num}{
		@include gutter($resp);
		@extend .clear_fix;
		width: auto !important;
	}
	@for $i from 1 through $num {
		.#{$children_class}#{$i}{
			float:left;
			width: #{100 / $num * $i}#{"%"};
		}
	}

	@if $resp{
		@each $key, $value in $breakpoints {
			@include mq($key) {
				@for $i from 1 through $num {
					.#{$key}_#{$children_class}#{$i}{
						float:left;
						width: #{100 / $num * $i}#{"%"};
					}
				}
			}
		}
	}
}
