.l-site_footer{
	background-color: #000;
	color: #fff;
	text-align: center;
	padding: 10% 0 3%;

	@include mq(sp) {
		padding: 15% 0 5%;
	}

	&__id {
		@include mq(liquid) {
			width: 13vw;
			margin-left: auto;
			margin-right: auto;
		}
		@include mq(sp) {
			width: 22%;
			margin: 0 auto;
		}

		a {
			display: block;
		}
	}

	&__nav {
		margin-top: 2%;

		@include mq(sp) {
			margin-top: 8%;
		}

		ul {
			display: flex;
			justify-content: center;

			li {
				position: relative;

				&:last-of-type {
					&::after {
						display: none;
					}
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 1px;
					height: 20px;
					background-color: #fff;

					@include mq(sp) {
						height: 10px;
					}
				}
			}
		}
		a {
			color: #fff;
			@include rem(font-size, 1.9rem);
			font-family: $roboto;
			padding: 0 25px;

			@include mq(liquid) {
				font-size: 1.28vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.0rem);
				padding: 0 15px;
			}
		}
	}

	&__copyright{
		margin-top: 9%;
		font-family: $roboto;
		@include rem(font-size, 1.4rem);
		letter-spacing: .5px;
		opacity: 0.6;

		@include mq(liquid) {
			font-size: .94vw;
		}

		@include mq(sp) {
			margin-top: 18%;
			@include rem(font-size, .8rem);
			letter-spacing: .1px;
		}
	}
}

.page_top{
	position:fixed;
	right:15px;
	bottom:15px;
	display: none;

	@include mq(sp) {
		display: none;
	}
	a{
		display:block;
		width:30px;
		height:30px;
		padding:10px;
		text-align:center;
		border-radius: 30px;
		color:#fff;
		background-color:$color_main;
		font-size:fs(20);
		&:hover{
			background-color:$color_accent;
			text-decoration:none;
		}
	}
}
