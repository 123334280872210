/*

works page

*/

body.single-works,
body.post-type-archive-works,
body.works{

	.p-works_detail {
		&__title {
			@include mq(sp, true) {
				text-align: center;
			}

			.title {
				@include rem(font-size, 3.0rem);
				font-weight: 400;
				letter-spacing: 1px;

				@include mq(liquid) {
					font-size: 2vw;
				}

				@include mq(sp) {
					@include rem(font-size, 1.6rem);
				}
			}
		}

		&__gallery {
			margin-top: 5%;
			//height: 950px;

			@include mq(sp) {
				width: 114%;
				margin-left: -7%;
			}

			img {
				width: 100%;
				height: auto;
			}

			.swiper-slide{
				height: auto;
				text-align: center;
				//background-color: #f9f9f9;
				position: relative;
				//height: 800px;
				//height: 30vw;

				@include mq(sp) {
					//height: 280px;
					//height: 80vw;
				}

				> * {
					// height: 100%;
					// max-width: unset !important;
					// width: auto !important;
					// max-height: 800px;
					// position: absolute;
					// left: 50%;
					// top: 50%;
					// transform: translate(-50%, -50%);
				}
			}

			.swiper-nav {
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				line-height: 1;
				margin-top: 2%;
				position: relative;

				@include mq(sp) {
					margin-top: 4%;
					margin-bottom: 4%;
					align-items: center;
				}

				.swiper-pagination {
					position: static;
				}

				.swiper-pagination-fraction {
					width: auto;
					@include rem(font-size, 1.8rem);
					margin: 0 25px;

					@include mq(sp) {
						margin: 0 20px;
						@include rem(font-size, 1.2rem);
					}
				}

				.swiper-button-next,
				.swiper-button-prev {
					position: static;
					height: 20px;
					background-size: auto 100%;
					margin-top: 0;
					// width: auto;
					position: relative;
					background-image: none;
					cursor: pointer;
					transition: opacity .3s;

					&::before,
					&::after {
						content: "";
						width: 2px;
						height: 12px;
						display: block;
						background-color: #9b9a9a;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%,-50%);
						@include mq(sp) {
							height: 7px;
						}
					}

					&::before {
						transform: rotate(-45deg);
						margin-top: -7px;

						@include mq(sp) {
							margin-top: -4px;
						}
					}

					&::after {
						transform: rotate(45deg);
						margin-bottom: -7px;

						@include mq(sp) {
							margin-bottom: -4px;
						}
					}

					&:hover {
						opacity: 0.6;
					}
				}

				.swiper-button-prev {
					&::before {
						transform: rotate(45deg);
					}

					&::after {
						transform: rotate(-45deg);
					}
				}
			}
		}

		&__content {
			margin-top: 4%;

			@include mq(sp, true) {
				display: flex;
				justify-content: space-between;
			}

			.data {
				@include mq(sp, true) {
					width: 60%;
				}

				dl {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					@include rem(font-size, 1.3rem);
					margin-top: 10px;

					@include mq(liquid) {
						font-size: .87vw;
					}

					@include mq(sp) {
						@include rem(font-size, 1.3rem);
					}

					dt {
						min-width: 4.5em;
						text-align-last: justify;

						@include mq(sp) {
							min-width: 4em;
						}

						span {
							// border: #aaa 1px solid;
							// padding: 12px 15px;
							// text-align: center;
							display: inline-block;
							width: 100%;
						}
					}

					dd {
						&::before {
							content: "　:　";
							display: inline-block;
						}
						display: flex;
						flex: 1;
					}
				}
			}

			.content {
				@include mq(sp, true) {
					width: 66%;
					@include rem(font-size, 1.6rem);
					line-height: 2.3;
				}

				@include mq(liquid) {
					font-size: 1.07vw;
				}

				@include mq(sp) {
					@include rem(font-size, 1.4rem);
					margin-top: 8%;
					line-height: 1.8;
				}
			}
		}

		&__btns {
			@include mq(sp, true) {
				width: 82%;
			}
			margin: 10% auto 0;

			ul {
				display: flex;
				justify-content: space-between;

				li {
					width: 48%;
				}
			}

			a {
				background-color: #2a2a2a;
				color: #fff;
				@include rem(font-size, 2.3rem);
				letter-spacing: 1px;
				display: block;
				text-align: center;
				padding: 40px 0;
				transition: opacity .3s;

				@include mq(liquid) {
					font-size: 1.55vw;
				}

				@include mq(sp) {
					@include rem(font-size, 1.2rem);
					padding: 15px 0;
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}
		}
	}

	.p-works_related {
		@include mq(sp) {
			margin-top: -3%;
			margin-bottom: -5%;
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
		}

		&__item {
			width: 31%;
			margin-right: 3.5%;
			margin-bottom: 3.5%;

			&:nth-child(3n) {
				margin-right: 0;
			}

			a {
				display: block;
				position: relative;

				em {
					@include rem(font-size, 1.3rem);
					font-family: $roboto;
					background-color: $color_text;
					color: #fff;
					text-align: center;
					padding: 3px 15px;
					font-style: normal;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
				}

				canvas {
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
				}

				img {
					&:nth-child(2) {
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						transition: opacity 0.3s;
					}
				}

				&:hover img:nth-child(2) {
					opacity: 1;
				}
			}
		}

		.btn {
			@include rem(font-size, 2.0rem);
			opacity: 0.6;
			font-family: $roboto;
			font-weight: 300;
			letter-spacing: 1px;

			@include mq(liquid) {
				font-size: 1.34vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.4rem);
			}
		}
	}

}

body.post-type-archive-works.paged {
	.p-works__item a em {
		display: none;
	}
}