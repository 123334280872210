.p-heading_lv1,
.heading_lv2,
.heading_lv3,
.heading_lv4{
	font-feature-settings: "palt";
	letter-spacing: .1em;
}
.p-heading_lv1{
	text-align: center;
	position: relative;

	&__inner {
		display: inline-block;
		position: relative;
	}

	.elem_en {
		@include rem(font-size, 5.5rem);
		font-family: $roboto;
		font-weight: 200;
		letter-spacing: 3px;
		position: relative;
		display: inline-block;
		line-height: 1;
		transition: font-size .2s;

		@include mq(liquid) {
			font-size: 3.3vw;
		}

		@include mq(sp) {
			letter-spacing: 2px;
			@include rem(font-size, 3.3rem);
		}

	}

	.momiji_01,
	.momiji_02 {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		img {
			vertical-align: middle;
			width: 100%;
			height: auto;
		}
	}

	.momiji_01 {
		//right: -50px;
		right: -3vw;
		//width: 36px;
		width: 2vw;

		@include mq(sp) {
			right: -25px;
			width: 18px;
		}
	}

	.momiji_02 {
		//right: -90px;
		right: -5.5vw;
		//width: 25px;
		width: 1.5vw;

		@include mq(sp) {
			right: -40px;
			width: 10px;
		}
	}

}

.p-heading_lv2{
	@include rem(font-size, 1.8rem);
	font-family: $roboto;
	font-weight: 500;
	position: relative;
	color: #a4a5a5;
	letter-spacing: 1px;
	display: inline-block;

	@include mq(liquid) {
		font-size: 1.21vw;
	}

	@include mq(sp) {
		@include rem(font-size, 1.2rem);
	}

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		width: 36px;
		height: 1px;
		background-color: #b2b2b2;

		@include mq(liquid) {
			width: 2.4vw;
		}

		@include mq(sp) {
			width: 23px;
		}
	}

	&::before {
		left: -50px;

		@include mq(liquid) {
			left: -3.4vw;
		}

		@include mq(sp) {
			left: -37px;
		}
	}

	&::after {
		right: -50px;

		@include mq(liquid) {
			right: -3.4vw;
		}

		@include mq(sp) {
			right: -37px;
		}
	}
}

.p-heading_lv3{
	@include rem(font-size, 4.5rem);
	font-weight: 100;
	letter-spacing: 2px;

	@include mq(liquid) {
		font-size: 3vw;
	}

	@include mq(sp) {
		@include rem(font-size, 2.5rem);
	}

	b {
		font-weight: 300;
		margin-left: 10px;
	}

	+ p {
		margin-top: 1.5%;
		line-height: 2;
		@include rem(font-size, 1.6rem);

		@include mq(liquid) {
			font-size: 1.08vw;
		}

		@include mq(sp) {
			@include rem(font-size, 1.4rem);
			text-align: left;
			padding: 0 6%;
			margin-top: 2%;
		}
	}
}

.heading_lv4{
	margin-top:20px;
	margin-bottom:10px;
}
