/*

contact page

*/

body.contact{

	.p-contact {
		max-width: 960px;
		margin: 0 auto;

		&__message {
			margin-top: 10%;
			@include rem(font-size, 2.0rem);

			@include mq(sp, true) {
				text-align: center;
			}

			@include mq(liquid) {
				font-size: 1.33vw;
			}

			@include mq(sp) {
				@include rem(font-size, 1.4rem);
			}

			.btn {
				margin-top: 80px;
				text-align: center;

				@include mq(sp) {
					margin-top: 40px;
				}
			}
		}

		&__form {
			margin-top: 8%;
			.error {
				background-color: #2a2a2a;
				color: #fff;
				padding: 4px 8px;
				display: block;
				border-radius: 1px;
				margin: 15px 0 5px;

				&::before {
					content: "⚠";
					margin-right: 5px;
				}
			}

			sup {
				font-style: normal;
				@include rem(font-size, 1.0rem);
			}

			input[type="text"],
			input[type="email"],
			textarea {
				-moz-appearance: none;
				-webkit-appearance: none;
				background-color: #EFEFEF;
				@include rem(font-size, 1.6rem);
				padding: 32px 20px;
				width: 100%;
				max-width: 100%;
				transition: border-color .6s;
				outline: none;
				border: none;
				box-sizing: border-box;

				@include mq(sp) {
					padding: 15px 20px;
				}

				&:focus {
					border-color: $color_main;
				}

				&.zip {
					vertical-align: middle;
					margin-left: 10px;

					@include mq(sp, true) {
						width: 30%;
					}

					@include mq(sp) {
						width: 50%;
					}
				}
			}

			.address {
				li {
					margin-bottom: 20px;

					@include mq(sp) {
						margin-bottom: 10px;
					}
				}
			}

			.notes {
				margin-top: 5px;
				@include rem(font-size, 1.3rem);
				color: #777;

				@include mq(liquid) {
					font-size: 1.2vw;
				}

				@include mq(sp, true) {
					display: inline-block;
				}

				@include mq(sp) {
					@include rem(font-size, 1.3rem);
				}
			}

			.mwform-radio-field {
				display: inline-block;
				margin-right: 15px;
			}

			input[type="radio"],
			input[type="checkbox"] {
				transform: scale(1.5);
				position: relative;
				top: -2px;
			}

			textarea {
				max-width: 748px;
				min-width: 100%;
				min-height: 275px;
				max-height: 500px;

				@include mq(sp) {
					min-height: 180px;
					margin-bottom: 0;
				}
			}

			table {
				width: 100%;
			}

			tr {
				@include mq(sp) {
					display: block;
				}
			}

			th,td {
				font-weight: 500;
				text-align: left;
				@include rem(font-size, 1.8rem);
				padding: 17px 0;
				vertical-align: middle;

				@include mq(liquid) {
					font-size: 1.2vw;
				}

				@include mq(sp) {
					display: block;
					padding: 10px 0;
					@include rem(font-size, 1.3rem);
				}
			}

			th {
				@include mq(sp, true) {
					width: 22%;
				}

				@include mq(sp) {
					padding-bottom: 0;
				}
			}

			td {
				@include mq(sp, true) {
					width: 78%;
				}
			}
		}

		&__info {
			margin-top: 4%;
			@include mq(sp) {
				@include rem(font-size, 1.3rem);
				margin-top: 3%;
			}
		}

		&__btns {
			text-align: center;
			margin-top: 8%;

			ul {
				@include mq(sp) {
					display: flex;
					flex-direction: column-reverse;
				}
			}

			li {
				display: inline-block;
				margin: 20px;

				@include mq(sp) {
					margin: 20px 40px;
				}

				&:empty {
					display: none;
				}

				span {
					&:empty {
						display: none;
					}
				}
			}
		}
	}

}

body.confirm {
	.loading {
		display: none !important;
	}
	.p-contact {
		&__message {
			@include mq(sp) {
				text-align: center;
			}
		}
		&__form {
			sup {
				display: none;
			}

			.notes {
				display: none;
			}
		}

		&__info {
			display: none;
		}
	}
}

body.thanks {
	.loading {
		display: none !important;
	}
	.p-contact {
		&__message {
			@include mq(sp) {
				text-align: center;
			}
		}
	}
}
