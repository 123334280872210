/*

post

*/

.post {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    //トップページ
    &__item {
        width: 49%;
        margin-bottom: 20px;

        @include mq(sp) {
            width: 100%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            @include mq(sp) {
                display: none;
            }
        }
    }

    //アーカイブページ
    &__list {
        margin-bottom: 40px;
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    //サイドバー
    &__archive {
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;

            .post__thumb div {
                transform: scale(1.2);
            }
        }
    }

    &__thumb {
        width: 15%;
        min-width: 105px;
        min-height: 105px;
        margin-right: 20px;
        overflow: hidden;
        border-radius: 4px;

        div {
            width: 100%;
            height: 100%;
            min-width: 105px;
            min-height: 105px;
            transition: transform .3s;
            background-size: cover;
            background-position: center;
        }
    }

    &__body {
        width: 82%;
        &__inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 95px;

        }
    }

    &__title {
        line-height: 1.5;
    }

    &__meta {
        line-height: 1;
    }

    &__category {
        @include rem(font-size, 1.2rem);
        line-height: 1;
        color: $color_main;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: $font_opensans;
    }

    &__date {
        @include rem(font-size, 1.3rem);
        line-height: 1;
        margin-bottom: 0;
    }

    //single
    &__detail {
        &__meta {
            display: flex;
            margin-bottom: 20px;
        }

        &__category {


            a {
                background-color: $color_main;
                color: #fff;
                font-family: $font_opensans;
                letter-spacing: 2px;
                padding: 0 10px;
                border-radius: 4px;
                margin-right: 20px;
                font-weight: bold;
                display: inline-block;
                border: $color_main 2px solid;
                transition: all .2s;

                &:hover {
                    text-decoration: none;
                    background-color: #fff;
                    color: $color_main;
                }
            }
        }

        &__date {
            letter-spacing: 2px;
            span {
                display: inline-block;
                margin-right: 10px;
                font-family: $font_opensans;
                font-weight: bold;
            }
        }

        &__title {
            @include rem(font-size, 3.0rem);
            margin-bottom: 35px;

            @include mq(sp) {
                @include rem(font-size, 2.0rem);
                margin-bottom: 20px;
            }
        }

        &__thumb {
            margin-bottom: 40px;
            @include mq(sp) {
                margin-bottom: 20px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }

        &__body {
            line-height: 3;

            h1 {
                
            }
        }
    }
}
